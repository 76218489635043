<template>
  <div class="box">
    <div :class="showTop ? 'heads ' : 'heads heads1'">
      <img src="/subject/searchReport/logo.png" alt="" />
      <div class="headlist">
        <el-menu
          v-if="windowWidth >= 500"
          :default-active="activeIndex"
          :class="showTop ? 'el-menu-demo' : 'heads1'"
          mode="horizontal"
          @select="handleSelect"
          text-color="#fff"
          style="border: 0"
          active-text-color="#f00000"
        >
          <el-menu-item index="1">发布会直播</el-menu-item>
          <el-menu-item index="2">专家团队介绍</el-menu-item>
          <el-menu-item index="3">会议议程</el-menu-item>
          <el-menu-item index="4">投研报告</el-menu-item>
          <el-menu-item index="5">媒体报道</el-menu-item>
          <el-menu-item index="6">往届回顾</el-menu-item>
          <el-menu-item index="7">支持媒体</el-menu-item>
          <el-menu-item index="8">关注我们</el-menu-item>
        </el-menu>
        <!-- <div v-for="(item, index) in headlist" :key="index"  class="head-name">
          <div @click.stop="selecttab(index)">{{item}}</div>
          <div  :class="cutter==index?'head-line':'' "></div>
        </div> -->
      </div>
    </div>
    <div class="float" v-if="showFloat" @mouseenter="showClose1" @mouseleave="closeClose">
      <img @click="live800" src="/subject/searchReport/float.png" alt="" />
      <img v-show="!showClose"   src="/subject/searchReport/close.png" alt="" />
      <img @click.stop="closeFloat" v-show="showClose" src="/subject/searchReport/closes.png" alt="" />
    </div>
    <div class="float1" @click="toTop" v-show="showTop" @mouseenter="showTops1" @mouseleave="closeTops">
      <img src="/subject/searchReport/tops.png" v-show="showTops" alt="" />
   
      <img src="/subject/searchReport/top.png"  v-show="!showTops"  alt="" />

      
    </div>
    <div class="bannerBox">
      <img class="banner" src="/subject/searchReport/topbg.png" alt="" />
      <img
        class="top-title1"
        src="/subject/searchReport/top-title.png"
        alt=""
      />
      <img
        class="top-title"
        src="/subject/searchReport/top-title1.png"
        alt=""
      />
    </div>
    <div class="content">
      <div class="part part_1" ref="targetElement">
        <div class="p1_box">
          <div class="p1-left">
            <img src="/subject/searchReport/title.png" alt="" />
            <div class="p1-content">
              红狮集团专家团队联袂全球金融资深分析师携手打造<span>2025年极具投资参考价值的投研报告</span>行业独创发布
            </div>
            <img
              @click="Login"
              class="p1-img1"
              src="/subject/searchReport/login.png"
              alt=""
            />
            <img
              @click="live800"
              class="p1-img"
              src="/subject/searchReport/request.png"
              alt=""
            />
            <div class="p1-content1">
              咨询客服获取投研报告及<span>打开密码</span>
            </div>
          </div>

          <div class="p1-right">
            <video
              class="video"
              src="/subject/searchReport/report.mp4"
              ref="videoPlayer"
              autoplay
              controls
              
            
            ></video>
            <!-- <img src="/subject/searchReport/teacherlist.png" alt="" />
            <div class="starttime">敬请期待12月18日</div> -->
          </div>
          <!-- <div class="swiperVideo" v-if="!ckplayerObject.live">
            <swiper ref="videoSwiper" :options="swiperVideoOption"> 
              <div class="swiper-slide" v-for="(item,i) in teacherSwiper" :key="i">
                <img @click="goVideo(i)" :src="item" />
              </div>
           
            </swiper>
          </div> -->
        </div>
      </div>
      <div class="part part_2" ref="targetElement2">
        <img src="/subject/searchReport/title2.png" alt="" />
        <div class="p2-flex">
          <div class="p2-flexs" @mouseenter="showCont" @mouseleave="closeCont">
            <img src="/subject/searchReport/teacher.png" alt="" />
            <div class="p2-box" v-show="showContent1">
              <div class="tops">
                <img src="/subject/searchReport/pen.png" alt="" />
                <span>交易箴言:</span>
              </div>
              <span class="tit">顺势交易不与市场对做</span>
              <span class="introduce">个人简介：</span>
              <div class="bott">
                台湾知名学府毕业，具备多年金融投资经验，自16年开始成为专职交易员，主要操作贵金属黄金、美国道琼斯指数及数字货币，热衷于区块链技术应用及数字货币领域分析，并且擅长技术分析应用，K线形态、技术指标、斐波那契、波浪理论等，运用独特的技术分析方式去推算市场惯性及时间循环，并看好未来区块链应用发展落地，去中心化金融崛起，并在数字货币领域靠着精湛的分析技术创造超过200%的做人成绩。
              </div>
            </div>
          </div>
          <div
            class="p2-flexs"
            @mouseenter="showCont1"
            @mouseleave="closeCont1"
          >
            <img src="/subject/searchReport/teacher2.png" alt="" />
            <div class="p2-box" style="padding-top: 30px" v-show="showContent2">
              <div class="tops">
                <img src="/subject/searchReport/pen.png" alt="" />
                <span>交易箴言:</span>
              </div>
              <span class="tit"
                >决不要因为失去耐心而退出市场，也决不要因为迫不及待而进入市场
              </span>
              <span class="introduce">个人简介：</span>
              <div class="bott">
                计算机科学硕士学位，曾在国外知名投资银行担任对冲经理人，特别专长于股票、期货对冲以及选择权价差交易。擅长以演算法找出选择权定价高估或低估的产品进行无风险套利。推算出风险平衡最佳化的股票与选择权投资组合，短线赚取价差获利，长线赚取大行情。致力于打造稳定赚取被动收入的价差组合，与出现行情时赚取高报酬低风险的投资组合。专长操作的金融产品为美股、期货、外汇、贵金属、指数选择权。
              </div>
            </div>
          </div>
          <div
            class="p2-flexs"
            @mouseenter="showCont2"
            @mouseleave="closeCont2"
          >
            <img src="/subject/searchReport/teacher3.png" alt="" />
            <div class="p2-box" v-show="showContent3">
              <div class="tops">
                <img src="/subject/searchReport/pen.png" alt="" />
                <span>交易箴言:</span>
              </div>
              <span class="tit">别人贪婪我恐惧，别人恐惧我贪婪 </span>
              <span class="introduce">个人简介：</span>
              <div class="bott">
                澳洲墨尔本大学财经硕士，投资经验十年，取得CFA美国财务分析师...等多项国内外金融证照；曾在私人投资基金任职宏观经济研究员，股指外汇原物料皆有广泛涉猎。擅长从政策与数据变化中寻找投资机会，辅以成交量、均线与RSI...等指标综合创造进出场点位，有一套独特的趋势解读看法。
              </div>
            </div>
          </div>
          <div
            class="p2-flexs"
            @mouseenter="showCont3"
            @mouseleave="closeCont3"
          >
            <img src="/subject/searchReport/teacher4.png" alt="" />
            <div class="p2-box" v-show="showContent4">
              <div class="tops">
                <img src="/subject/searchReport/pen.png" alt="" />
                <span>交易箴言:</span>
              </div>
              <span class="tit"
                >时间就是金钱，进场时机永远比你的目标价位重要
              </span>
              <span class="introduce">个人简介：</span>
              <div class="bott">
                美国名校毕业。研究黄金外汇和股指多年，具有长期指导实战交易的经历，擅长宏观经济和货币政策的研究，央行官员讲话和报告的分析解读，以此来洞悉基本面的走向。技术分析层面擅长主力區間，市场主力进出场时机判断有丰富经验。价格技术分析的优势在于筹码面交易的研究；对15分钟、1小时、4小时和日线之间的切换研究有深刻体会。同时在日内开盘，收盘价，主力进场位判断和止损风控方面有多年浸淫。
              </div>
            </div>
          </div>
        </div>
        <div class="p2-content">
          分析师的观点仅代表分析师个人立场，不代表本公司立场，投资有风险，人市需谨慎！
        </div>
        <!-- <img  src="/subject/newReport/p2Banner.png" alt="" /> -->
        <!-- <p>
          <img
            @click="live800"
            src="/subject/newReport/wap/live800.png"
            alt=""
          />
          <img @click="Login" src="/subject/newReport/login.png" alt="" />
        </p> -->
        <!-- <img
          class="live800text"
          src="/subject/newReport/wap/live800text.png"
          alt=""
        /> -->
      </div>
      <div class="part part_3" ref="targetElement3">
        <!-- <img src="/subject/searchReport/title3.png" alt="" /> -->
        <div class="p1_box">
          <div class="p1-right">
            <img
              style="height: 632px"
              src="/subject/searchReport/banner3.png"
              alt=""
            />
            <div class="part3-title">2025全球投资机会研究报告 · 发布会议程</div>

            <div class="timelist">
              <div class="left-time">
                <div>
                  <span>14:00</span>
                  <div class="left-flex">
                    <img src="/subject/searchReport/icon.png" alt="" />
                    <div class="time-line" style="height: 40px"></div>
                  </div>
                  <div class="times">
                    <span>开场致词</span>
                  </div>
                </div>
                <div>
                  <span>14:05</span>
                  <div class="left-flex">
                    <img src="/subject/searchReport/icon.png" alt="" />
                    <div class="time-line" style="height: 87px"></div>
                  </div>
                  <div class="times">
                    <span>主题演讲</span>
                    <div class="times1">
                      <img src="/subject/searchReport/avatar.png" alt="">
                      <div>
                         <span>主题：美联储降息循环开启对全球资产价格的影响</span>
                       <span>专家：吴龙（红狮金融研究院资深分析师）</span>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div>
                  <span>14:35</span>
                  <div class="left-flex">
                    <img src="/subject/searchReport/icon.png" alt="" />
                    <div class="time-line" style="height: 104px"></div>
                  </div>
                  <div style="display: flex;flex-direction: column;">
                     <div class="times">
                    <span>主题演讲</span>
                    <div class="times1">
                      <img src="/subject/searchReport/avatar1.png" alt="">
                      <div>
                        <span>主题：中国经济稳中求进展，政策组合带动经济复苏</span>
                        <span>欧洲央行降息对欧元与经济的影响</span>

                        <span>专家：万良（红狮金融研究院资深分析师）</span>
                      </div>
                    </div>
                    
                  </div>
                  
                  </div>
                </div>
                
                <div>
                  <span>15:05</span>
                  <img src="/subject/searchReport/icon.png" alt="" />
                  <div class="times">
                    <span>主题演讲</span>
                     <div class="times1">
                      <img src="/subject/searchReport/avatar2.png" alt="">
                      <div>
                        <span>主题：2025年全球各大资产投资热点分析</span>
                    <span>专家：马文（红狮金融研究院资深分析师）</span>
                      </div>
                    </div>
                    
                  </div>
                </div>

                <!-- <div class="line"></div> -->
              </div>
              <div class="right-time">
                <div>
                  <span>15:35</span>
                  <div class="left-flex">
                    <img src="/subject/searchReport/icon.png" alt="" />
                    <div class="time-line" style="height: 104px"></div>
                  </div>
                  <div style="display: flex;flex-direction: column;">
                   <div class="times">
                    <span>主题演讲</span>
                     <div class="times1">
                      <img src="/subject/searchReport/avatar3.png" alt="">
                      <div>
                        <span>主题：地缘局势震荡对全球投资的影响</span>
                        <span>美国总统大选对全球投资品种的后续影响</span>

                    <span>专家：明杰（红狮金融研究院资深分析师）</span>
                      </div>
                    </div>
                   
                  </div>
                  
                  </div>
                  
                </div>
                <div>
                  <span>16:05</span>
                  <div class="left-flex">
                    <img src="/subject/searchReport/icon.png" alt="" />
                    <div class="time-line" style="height: 87px"></div>
                  </div>
                  <div class="times">
                    <span>圆桌会议</span>
                      <div class="times1">
                      <img src="/subject/searchReport/avatar3.png" alt="">
                      <div>
                         <span>主题：地缘局势震荡对全球投资的影响</span>
                    <span>专家：吴龙、万良、马文、明杰</span>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div>
                  <span>16:35</span>
                  <img src="/subject/searchReport/icon.png" alt="" />
                  <div class="times">
                    <span>答疑互动环节</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p1-left">
            <img src="/subject/searchReport/title3.png" alt="" />

            <div class="p1-content">
              多年投资经验不断总结，不断实践数月精心准备打造<span>2025年极具参考价值的投资方案内容</span>汇集投资干货，极具实操价值
            </div>
            <img
              class="p1-img1"
              @click="Login"
              src="/subject/searchReport/login.png"
              alt=""
            />
            <img
              @click="live800"
              class="p1-img"
              src="/subject/searchReport/request.png"
              alt=""
            />
            <div class="p1-content1">
              咨询客服获取投研报告及<span>打开密码</span>
            </div>
          </div>

          <!-- <div class="swiperVideo" v-if="!ckplayerObject.live">
            <swiper ref="videoSwiper" :options="swiperVideoOption"> 
              <div class="swiper-slide" v-for="(item,i) in teacherSwiper" :key="i">
                <img @click="goVideo(i)" :src="item" />
              </div>
           
            </swiper>
          </div> -->
        </div>
      </div>
      <div class="part part_4" ref="targetElement4">
        <img src="/subject/searchReport/title4.png" alt="" />

        <div class="p1_box">
          <div class="p1-left">
            <div class="p1-content">
              股市、期货、黄金、外汇、数字货币、房地产具体投资策略一一展开内容翔实，助您实战一臂之力
            </div>
            <div class="p1-content2"><span>名额有限</span> 送完即止！</div>
            <img
              class="p1-img1"
              @click="Login"
              src="/subject/searchReport/login.png"
              alt=""
            />
            <img
              @click="live800"
              class="p1-img"
              src="/subject/searchReport/request.png"
              alt=""
            />
            <div class="p1-content1">
              咨询客服获取投研报告及<span>打开密码</span>
            </div>
          </div>
          <div class="p1-right">
            <img src="/subject/searchReport/cover.png" alt="" />
            <img src="/subject/searchReport/cover1.png" alt="" />
            <img src="/subject/searchReport/cover2.png" alt="" />
          </div>

          <!-- <div class="swiperVideo" v-if="!ckplayerObject.live">
            <swiper ref="videoSwiper" :options="swiperVideoOption"> 
              <div class="swiper-slide" v-for="(item,i) in teacherSwiper" :key="i">
                <img @click="goVideo(i)" :src="item" />
              </div>
           
            </swiper>
          </div> -->
        </div>
      </div>
      <div class="part part_5" ref="targetElement5">
        <img
          src="/subject/searchReport/title5.png"
          style="margin-bottom: 60px"
          alt=""
        />
        <div class="swiperBox">
          <swiper ref="mySwiper" :options="swiperOption">
            <div class="swiper-slide">
              <img src="/subject/searchReport/banner2.png" />
              <div class="bottoms">
                美联社报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/reports1.png" />
              <div class="bottoms">
                目标国家/行业相关报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/reports2.png" />
              <div class="bottoms">
                百度报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/reports3.png" />
              <div class="bottoms">
                南方经济/新浪财经报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/xinlang.png" />
              <div class="bottoms">
                新浪财经报道《2024年投资机会研究报告》
              </div>
            </div>
             <div class="swiper-slide">
              <img src="/subject/searchReport/product.png" />
              <div class="bottoms">
                中国产业报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/compony.png" />
              <div class="bottoms">
                南方企业报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/compony.png" />
              <div class="bottoms">
                南方企业报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/guge.png" />
              <div class="bottoms">
                谷歌报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/yahu.png" />
              <div class="bottoms">
                雅虎报道《2024年投资机会研究报告》
              </div>
            </div>
             <div class="swiper-slide">
              <img src="/subject/searchReport/gelun.png" />
              <div class="bottoms">
                美国哥伦报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/fox.png" />
              <div class="bottoms">
                福克斯报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/fox1.png" />
              <div class="bottoms">
                福克斯报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/Americ.png" />
              <div class="bottoms">
                美国广播公司报道《2024年投资机会研究报告》
              </div>
            </div>
             <div class="swiper-slide">
              <img src="/subject/searchReport/Americ1.png" />
              <div class="bottoms">
                美国广播公司报道《2024年投资机会研究报告》
              </div>
            </div>
             <div class="swiper-slide">
              <img src="/subject/searchReport/Americ2.png" />
              <div class="bottoms">
                美国广播公司报道《2024年投资机会研究报告》
              </div>
            </div>
             <div class="swiper-slide">
              <img src="/subject/searchReport/Americ3.png" />
              <div class="bottoms">
                美国广播公司报道《2024年投资机会研究报告》
              </div>
            </div>
            <!-- 分页器 -->
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
      <div class="part part_6" ref="targetElement6">
        <img src="/subject/searchReport/title6.png" alt="" />
        <!-- <img src="/subject/searchReport/banner.png" alt="" /> -->
        <div class="swiperBox">
          <swiper ref="mySwiper" :options="swiperOption">
            <div class="swiper-slide" @click.stop="toUrl1">
              <img src="/subject/searchReport/banner.png" />
            </div>
            <div class="swiper-slide" @click.stop="toUrl">
              <img src="/subject/searchReport/banner1.png" />
            </div>
            <!-- 分页器 -->
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
      <div class="part part_7" ref="targetElement7">
        <img
          src="/subject/searchReport/title7.png"
          style="margin-bottom: 47px"
          alt=""
        />
        <img src="/subject/searchReport/media.png" alt="" />
      </div>
      <div class="part part_8" ref="targetElement8">
        <div class="part8-centent">
          <div class="partLeft">
            <img src="/subject/searchReport/phone.png" alt="" />
          </div>

          <div class="partRight">
            <img
              src="/subject/searchReport/title8.png"
              class="advantage"
              alt=""
            />

            <div class="appCode">
              <div class="app-box">
                <div class="codeBody">
                  <div>MT4账户专用版本</div>
                  <div class="codeBox">
                    <div class="codeItem">
                      <Qrcode
                        :link="iosMt4DownloadUrl"
                        :size="126"
                        :iconSize="30"
                      />
                      <div>iOS</div>
                    </div>
                    <div class="codeItem">
                      <Qrcode :link="androidMt4DownloadUrl" :size="126" />
                      <div>Android</div>
                    </div>
                  </div>
                </div>
                <div class="codeBody">
                  <div>MT5账户专用版本</div>
                  <div class="codeBox">
                    <div class="codeItem">
                      <Qrcode :link="iosMt5DownloadUrl" :size="126" />
                      <div>iOS</div>
                    </div>
                    <div class="codeItem">
                      <Qrcode :link="androidDownloadUrl" :size="126" />
                      <div>Android</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="serveBox">
              <div class="serveBox-title">下载官方APP，发现更多智富可能</div>
              <div class="serveBox-item" style="margin-top: 16px">
                <div>1、MT4版本用MT4账户，MT5版本用MT5账户，两者不互通；</div>
              </div>
              <div class="serveBox-item">
                <div>
                  2、本平台2024.8.17日之前开立的是MT4账户，之后开立的账户以开户时所选账户类型为准，
                  如果不确定，请联系<span
                    class="serveBox-serve"
                    @click="live800"
                    >在线客服</span
                  >查询。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Contactus></Contactus>
  </div>
</template>

<script>
import Contactus from "../../Contactus.vue";
import { livingRoomNow } from "../../../../api/info";
import videojs from "video.js";
import Qrcode from "../../QrCode/index.vue";
import { mapState } from "vuex";
export default {
  name: "newReportPC",
  components: {
    Contactus,
    Qrcode,
  },
  data() {
    return {
      isVideo: "https://www.rlcvipltd.net/static/video/v-0320.mp4",
      // swiper 参数
      swiperOption: {
        slidesPerView: 1,
        // swiper图片之间的间隔
        spaceBetween: 0,
        centeredSlides: true,
        grabCursor: true,
        loop: true,
        preventClicks: true,
        //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        swiperData: [{ img: "" }],
      },
      swiperVideoOption: {
        slidesPerView: 5,
        // swiper图片之间的间隔
        spaceBetween: 20,
        centeredSlides: true,
        grabCursor: true,
        loop: true,
        preventClicks: true,
        //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      screenWidth: null,
      scroll: Number,
      timer: null,
      myPlyer: null,
      options: null,

      activeIndex: null, //菜单选中
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      targetElements: null, //菜单距离屏幕的距离
      showFloat: true, //浮窗

      showContent1: true,
      showContent2: false,
      showContent3: false,
      showContent4: false,
      showTop: true, //回到顶部
      showTops:false,//回到顶部按钮
      showClose:false,//显示关闭按钮
    };
  },

  computed: {
    ckplayerObject() {
      return this.$store.state.home.ckplayerObject;
    },
    teacherPlayBack() {
      return this.$store.state.home.teacherPlayBack;
    },
    ...mapState("common", {
      androidMt4DownloadUrl: "androidMt4DownloadUrl",
      androidDownloadUrl: "androidDownloadUrl",
      iosMt4DownloadUrl: "iosMt4DownloadUrl",
      iosMt5DownloadUrl: "iosMt5DownloadUrl",
    }),
  },
  created() {
    // 传给App.vue,展示tabbar
    this.$emit("controlShow", true);
  },
  beforeDestroy() {
    //在 beforeDestroy生命周期函数中销毁定时器
    clearInterval(this.timer);
    this.timer = null;
  },
  watch: {
    screenWidth: function (n) {
      if (n < 500) {
        this.$router.push({
          name: "searchReportWap",
        });
      }
    },
   
  },
  mounted() {

    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener('click',this.playVideo);
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    // setTimeout(()=>{
    //   this.$nextTick(() => {
    //     this.initVideo(this.isVideo);
    //   })
    // },500)
  },
  // beforeDestroy() {
  //   this.myPlyer.dispose();
  // },
  methods: {
    playVideo(){
        this.$refs.videoPlayer.play();
      
    },
    // 鼠标移入顶部按钮
    showTops1(){
       this.showTops=true
    },
    // 鼠标移除
    closeTops(){
       this.showTops=false
      
    },
    // 鼠标移入
    showClose1(){
       this.showClose=true
    },
    closeClose(){
       this.showClose=false

    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    showCont() {
      this.showContent1 = true;
    },
    closeCont() {
      this.showContent1 = false;
    },
    showCont1() {
      this.showContent2 = true;
    },
    closeCont1() {
      this.showContent2 = false;
    },
    showCont2() {
      this.showContent3 = true;
    },
    closeCont2() {
      this.showContent3 = false;
    },
    showCont3() {
      this.showContent4 = true;
    },
    closeCont3() {
      this.showContent4 = false;
    },
    // 关闭弹窗
    closeFloat() {
      this.showFloat = false;
    },
    // 点击跳转到指定位置
    scrollToTarget() {
      // const targetElements = this.$refs.targetElement;
      const targetPosition = this.targetElements.offsetTop - 150;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    },
    // 选择导航
    handleSelect(key) {
      console.log(key);
      this.$emit("getKey", key);
      sessionStorage.setItem("key", key);
      // this.getSessinKey(key);
      switch (key) {
        case "1":
          this.activeIndex = key;

          this.targetElements = this.$refs.targetElement;
          this.scrollToTarget();
     this.$refs.videoPlayer.play();

          break;
        case "2":
          this.activeIndex = key;
          this.targetElements = this.$refs.targetElement2;

          this.scrollToTarget();

          break;
        case "3":
          this.activeIndex = key;
          this.targetElements = this.$refs.targetElement3;

          this.scrollToTarget();

          break;
        case "4":
          this.activeIndex = key;
          this.targetElements = this.$refs.targetElement4;

          this.scrollToTarget();

          break;
        case "5":
          this.activeIndex = key;
          this.targetElements = this.$refs.targetElement5;

          this.scrollToTarget();

          break;
        case "6":
          this.activeIndex = key;
          this.targetElements = this.$refs.targetElement6;

          this.scrollToTarget();

          break;
        case "7":
          this.activeIndex = key;
          this.targetElements = this.$refs.targetElement7;

          this.scrollToTarget();

          break;

        case "8":
          this.activeIndex = key;

          this.targetElements = this.$refs.targetElement8;

          this.scrollToTarget();

          break;
      }
    },
    selecttab(e) {
      console.log(e);
      this.cutter = e;
    },
    // 获取是否直播以及直播流
    init() {
      livingRoomNow({ platid: 1 })
        .then((res) => {
          if (res.Status == 0 && res.content.length > 0) {
            this.$store.commit("home/set_ckplayerObject", {
              video: res.content[0].param.pcurl,
              live: true,
            });
            this.isVideo = res.content[0].param.pcurl;
            setTimeout(() => {
              this.initVideo(this.isVideo);
            }, 500);
          } else {
            this.$store.commit("home/set_ckplayerObject", {
              video: "https://www.rlcvipltd.net/static/video/v-0320.mp4",
              live: false,
            });
            this.isVideo = "https://www.rlcvipltd.net/static/video/v-0320.mp4";
            // this.myPlyer.dispose();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    live800() {
      window.open(this.$parent.live800UrlUc);
    },
    Login() {
      window.open("https://www.rlcvipltd.net/uc/login");
    },
    toUrl() {
      window.open("https://www.rlcproltd.net/#/subject/tg_9654/index");
    },
    toUrl1() {
      window.open("https://www.rlcproltd.net/#/subject/newReport/index");
    },
    goBank() {
      window.location.href = window.location.origin;
    },
    // 初始化videojs内容
    initVideo(nowPlayVideoUrl) {
      // 这些options属性也可直接设置在video标签上，见 muted
      this.options = {
        autoplay: true, // 设置自动播放
        controls: true, // 显示播放的控件
        sources: [
          // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
          {
            src: nowPlayVideoUrl,
            type: "application/x-mpegURL", // 告诉videojs,这是一个hls流
          },
        ],
      };
      // videojs的第一个参数表示的是，文档中video的id
      this.myPlyer = videojs(
        "videoPlayer",
        this.options,
        function onPlayerReady() {
          console.log("onPlayerReady 中的this指的是：", this); // 这里的this是指Player,是由Videojs创建出来的实例
          console.log(this.myPlyer === this); // 这里返回的是true
        }
      );
    },
    // 控制顶部导航栏的定位
    handleScroll() {
      let tops = this.$refs.targetElement.offsetTop - 60;
      let scrollY = window.scrollY; //获取滚动后的高度
     
      if (scrollY >= tops) {

        this.showTop = true;

      } 
           
      else {
        this.showTop = false;
        this.activeIndex = null;
      }
    },
    // 下载报告书
    downloadReport() {
      window.open(
        "https://oss.0790jiaxiao.com/Report/SpingSummerInvestment2023032801.pdf"
      );
    },
    // 获取老师直播回放
    async playBack() {
      const data = {
        platid: 1,
        sort: "ut,DESC",
        type: 0,
        page: 0,
        size: 10,
      };
      await this.$store.dispatch("home/getPlayBack", data);
    },
    // 跳转视频回放
    goVideo(attr) {
      // console.log(this.teacherPlayBack);
      switch (attr) {
        case 0:
          window.open(this.teacherPlayBack[4].fpath);
          break;
        case 1:
          window.open(this.teacherPlayBack[3].fpath);
          break;
        case 2:
          window.open(this.teacherPlayBack[2].fpath);
          break;
        case 3:
          window.open(this.teacherPlayBack[1].fpath);
          break;
        case 4:
          window.open(this.teacherPlayBack[0].fpath);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.heads {
  width: 100%;
  height: 100px;
  background: #070507;
  padding: 30px 200px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  img {
    width: 248px;
    height: 50px;
    margin-right: 171px;
  }
  .headlist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #ffffff;

    .head-name {
      margin-right: 46px;
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .head-line {
      width: 40px;
      height: 4px;
      background: #f00000;
      margin-top: 10px;
    }
  }
  .el-menu-demo {
    background: #070507;
  }
}

.heads1 {
  background: rgba(7, 5, 7, 0);
}
.float {
  position: fixed;
  top: 30%;
  right: 20px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.float1 {
  position: fixed;
  top: 82%;
  right:0px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  img{
    width: 71px;
    height: 71px;
  
  }
}
.box {
  // width: 100%;
  height: auto;
  max-width: 1920px;
  min-width: 1640px;

  .bannerBox {
    width: 100%;
    position: relative;
    .banner {
      width: 100%;
      display: block;
    }
    .top-title1 {
      position: absolute;
      top: 20%;
      left: 21%;

      height: 398px;
    }
    .top-title {
      position: absolute;
      top: 70%;
      left: 40%;

      height: 192px;
    }
    .logo {
      position: absolute;
      top: 30px;
      left: 50px;
      cursor: pointer;
    }
  }

  .content {
    width: 100%;
    height: 7130px;
    // background: url('/subject/newReport/background.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 40px 0;
    margin-bottom: 16%;
    .part {
      // width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      p {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 3.5%;
        left: 0;
        box-sizing: border-box;
        img {
          width: 350px;
          margin: 0 30px;
          cursor: pointer;
        }
      }
      .live800text {
        width: 268px;
        position: absolute;
        bottom: 1%;
        left: 32%;
      }
    }
    .part_1 {
      // width: 100%;
      background: url("/subject/searchReport/bg.png") no-repeat;
      background-size: 100% 100%;
      .p1_box {
        // width: 100%;
        // height: 968px;
        padding: 236px 0px 160px 4%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        .p1-left {
          display: flex;
          flex-direction: column;
          // align-items: center;
          margin-right: 125px;
        }
        .p1-right {
          position: relative;
          .video {
            width: 1000px;
            height: 560px;
          }
          .starttime {
            position: absolute;
            right: 26px;
            bottom: 17px;
            width: 105px;
            height: 13px;
            font-size: 12px;

            font-weight: 400;
            text-align: right;
            color: #ffffff;
            letter-spacing: 0.6px;
          }
        }
        .p1-left img:nth-child(1) {
          width: 196px;
          height: 170px;
        }

        .p1-img {
          width: 196px;
          height: 46px;
          margin: 20px 0;
          cursor: pointer;
        }
        .p1-img1 {
          width: 196px;
          height: 46px;
          cursor: pointer;
        }
        .p1-content {
          width: 235px;
          height: 163px;
          font-size: 18px;
          margin: 32px 0 51px 0;
          font-weight: 700;
          text-align: left;

          line-height: 36px;
          color: #333333;
          span {
            color: #f00000;
          }
        }
        .p1-content1 {
          width: 235px;
          width: 180px;
          height: 13px;
          font-size: 12px;

          font-weight: 400;
          text-align: left;
          color: #333333;
          span {
            color: #f00000;
            font-weight: 600;
          }
        }
        .video {
          width: 1363px;
          height: 660px;
        }
        .swiperVideo {
          width: 1340px;
          height: 190px;
          position: absolute;
          bottom: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          .swiper-container {
            width: 100%;
            height: 100%;
            .swiper-wrapper {
              .swiper-slide {
                img {
                  width: 100%;
                  height: auto;
                }
                // img:hover {
                //   border: 2px solid #fff;
                //   border-radius: 10px;
                // }
              }
            }
          }
        }
      }
    }
    .part_2 {
      // width: 100%;
      padding: 50px 300px 91px;
      background: url("/subject/searchReport/bg2.png") no-repeat;
      background-size: 100% 100%;
      p {
        bottom: 4%;
      }
      .p2-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 37px;
      }
      .p2-flexs {
        display: flex;
        align-items: center;
      }
      .p2-box {
        padding: 50px 20px 57px 26px;
        background: #fff;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .tops {
          display: flex;
          align-items: center;
          span {
            font-size: 16px;

            font-weight: 700;
            text-align: left;
            color: #f00000;
            margin-left: 6px;
          }
        }
        .tit {
          margin: 12px 0 25px 0;
          font-size: 16px;
          text-align: left;
          color: #f00000;
        }
        .introduce {
          font-weight: 700;
          text-align: left;
          color: #1a1a1a;
        }
        .bott {
          width: 310px;
          height: 292px;
          font-size: 16px;

          text-align: left;
          color: #999999;
          line-height: 30px;
        }
      }
      .p2-content {
        margin-top: 26px;
        text-align: center;
        //  width: 449px;
        height: 13px;
        font-size: 12px;

        font-weight: 400;
        text-align: center;
        color: #cccccc;
      }
    }
    .part_3 {
      width: 100%;
      margin-top: 40px;
      p {
        bottom: 6%;
      }
      .live800text {
        bottom: 2.2%;
      }

      .p1_box {
        // width: 100%;
        // height: 968px;
        padding: 236px 0px 160px 4%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        .p1-left {
          display: flex;
          flex-direction: column;
          // align-items: center;
          margin-left: 51px;
        }
        .p1-right {
          position: relative;
          .part3-title {
            color: #fff;
            font-weight: 700;
            font-size: 36px;
            position: absolute;
            top: 20px;
            left: 20%;
            text-align: center;
          }
          .starttime {
            position: absolute;
            right: 26px;
            bottom: 17px;
            width: 105px;
            height: 13px;
            font-size: 12px;

            font-weight: 400;
            text-align: right;
            color: #ffffff;
            letter-spacing: 0.6px;
          }
          .timelist {
            position: absolute;
            top: 90px;
            left: 60px;
            font-size: 18px;

            font-weight: 700;
            text-align: left;
            color: #ffffff;
            display: flex;
            // align-items: center;
            .time-line {
              width: 0px;
              height: 234px;
              opacity: 0.4;
              border: 1px dashed #ffffff;
            }
            .left-flex {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .left-time,
            .right-time {
              display: flex;
              flex-direction: column;
            }
            .left-time {
              margin-right: 50px;
            }
            div {
              display: flex;
              // align-items: center;
              img {
                margin: 0 18px;
                width: 20px;
                height: 20px;
              }
              .times {
                display: flex;
                flex-direction: column;
                // align-items: center;
              }
              .times1{
                display: flex;
                align-items: center;
                img{
                  width: 41px;
                  height: 41px;
                  margin: 0 18px 0 0;
                }
                div{
                  display: flex;
                  flex-direction: column;
                  // align-items: center;
                  font-size: 16px;

                text-align: left;
                color: #ffffff;
                line-height: 24px;
                }
              }
              .times span:nth-child(1) {
                margin-bottom: 10px;
              }
             
            }
          }
        }
        .p1-left img:nth-child(1) {
          width: 247px;
          height: 112px;
        }

        .p1-img {
          width: 196px;
          height: 46px;
          margin: 20px 0;
          cursor: pointer;
        }
        .p1-img1 {
          width: 196px;
          height: 46px;
          cursor: pointer;
        }
        .p1-content {
          width: 235px;
          height: 163px;
          font-size: 18px;
          margin: 32px 0 51px 0;
          font-weight: 700;
          text-align: left;
          color: #333333;
          span {
            color: #f00000;
          }
          line-height: 36px;
        }
        .p1-content1 {
          width: 235px;
          width: 180px;
          height: 13px;
          font-size: 12px;

          font-weight: 400;
          text-align: left;
          color: #333333;
          span {
            color: #f00000;
            font-weight: 600;
          }
        }
      }
    }
    .part_4 {
      // width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 40px;
      padding: 50px 300px 91px;
      background: url("/subject/searchReport/bg4.png") no-repeat;
      background-size: 100% 100%;
      .p1_box {
        // width: 100%;
        // height: 968px;
        margin-top: 37px;
        // padding: 0 300px 160px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        .p1-left {
          display: flex;
          flex-direction: column;
          // align-items: center;
          margin-right: 71px;
        }
        .p1-right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          img {
            width: 316px;
            height: 446px;
            margin-right: 20px;
            box-shadow: 8px 8px 0px 0px rgba(219, 0, 0, 1);
          }
          .starttime {
            position: absolute;
            right: 26px;
            bottom: 17px;
            width: 105px;
            height: 13px;
            font-size: 12px;

            font-weight: 400;
            text-align: right;
            color: #ffffff;
            letter-spacing: 0.6px;
          }
        }
        .p1-left img:nth-child(1) {
          width: 247px;
          height: 112px;
        }

        .p1-img {
          width: 196px;
          height: 46px;
          margin: 20px 0;
          cursor: pointer;
        }
        .p1-img1 {
          width: 196px;
          height: 46px;
          cursor: pointer;
        }
        .p1-content {
          width: 235px;
          height: 163px;
          font-size: 18px;
          margin: 32px 0 0px 0;
          font-weight: 700;
          text-align: left;
          color: #fff;

          line-height: 36px;
        }
        .p1-content2 {
          width: 235px;

          font-size: 18px;
          margin: 0 0 51px 0;
          font-weight: 700;
          text-align: left;
          color: #fff;
          span {
            color: #f00000;
          }
          line-height: 36px;
        }
        .p1-content1 {
          width: 235px;
          width: 180px;
          height: 13px;
          font-size: 12px;

          font-weight: 400;
          text-align: left;
          color: #fff;
          span {
            color: #f00000;
            font-weight: 600;
          }
        }
      }
      .part4box {
        width: 100%;
        height: 1735px;
        background: url("/subject/newReport/p4Banner.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }
      p {
        bottom: 3%;
      }
      .download {
        position: absolute;
        cursor: pointer;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .part_5 {
      width: 100%;
      margin-top: 40px;
      .part5-content {
        margin-top: 37px;
        position: relative;
        .bottoms {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 1200px;
          height: 88px;
          background: linear-gradient(
            0deg,
            #000000 0%,
            rgba(0, 0, 0, 0.02) 100%
          );

          font-size: 16px;

          text-align: center;
          color: #fffefe;
          line-height: 30px;
          p {
            margin-bottom: 25px;
          }
        }
      }
      .bottoms {
        position: absolute;

        bottom: 0%;
        width: 60%;
        height: 88px;
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.02) 100%);

        font-size: 16px;

        text-align: center;
        color: #fffefe;
        line-height: 30px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          margin-bottom: 25px;
        }
      }
      .swiperBox {
        width: 100%;
        height: 497px;

        .swiper-container {
          width: 100%;
          height: 497px;

          .swiper-wrapper {
            .swiper-slide {
              img {
               
                height: 497px;
                object-fit: cover;
              }

              /* Center slide text vertically */
              display: -webkit-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              -webkit-justify-content: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;
              transition: 300ms;
            }

            .swiper-slide-active,
            .swiper-slide-duplicate-active {
              img {
                height: 470px;
              }
            }
          }
        }
      }
    }
    .part_6 {
      width: 100%;

      background: url("/subject/searchReport/bg4.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 103px;
      padding: 72px 0 81px;
       .swiperBox {
        width: 100%;
        height: 504px;

        .swiper-container {
          width: 100%;
          height: 504px;

          .swiper-wrapper {
            .swiper-slide {
              img {
               
                height: 504px;
                object-fit: cover;
              }

              /* Center slide text vertically */
              display: -webkit-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              -webkit-justify-content: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;
              transition: 300ms;
            }

            .swiper-slide-active,
            .swiper-slide-duplicate-active {
              img {
                height: 504px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .part_7 {
      padding: 82px 0px 85px;
    }
    .part_8 {
      margin-top: 85px;
      padding: 95px 0 0px 112px;
      background: url("/subject/searchReport/bg4.png") no-repeat;
      background-size: 100% 100%;
      .part8-centent {
        display: flex;
        align-items: center;

        margin: 0 auto;
        // margin-bottom: 100px;

        .partLeft {
          width: 52%;
        }

        .phone {
          display: inline-block;
          vertical-align: top;
          margin-right: 30px;
        }

        .partRight {
          display: inline-block;
          margin-bottom: 10%;
          .advantage {
            margin-bottom: 32px;
          }
          // margin-left: 500px;

          .ewm {
            width: 258px;
            height: 258px;
            margin: 0 auto;
          }
        }

        ul {
          li {
            width: 356px;
            height: 74px;
            text-align: center;
            line-height: 74px;
            color: #e4760b;
            font-size: 26px;
            margin-bottom: 35px;
            margin-left: 40px;
            font-weight: bold;
            border: 1px solid #e4760b;
            border-radius: 10px;
            display: inline-block;
          }
        }
        .appCode {
          display: flex;
          // justify-content: center;
          flex-direction: column;
          text-align: center;

          width: 615px;

          .app-box {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          // height: 302px;

          .appcode-title {
            font-size: 14px;

            font-weight: 400;
            text-align: center;
            color: #ff3333;
            line-height: 28px;
            margin-bottom: 13px;
          }
          .codeBody {
            > div:first-child {
              font-weight: 700;
              margin-bottom: 20px;
              // font-size: 20px;
              color: #fff;
            }
            .codeBox {
              display: flex;
              justify-content: center;
              margin-bottom: 15px;
              margin-right: 30px;
              .codeItem {
                &:first-child {
                  margin-right: 15px;
                }
                .canvasCode {
                  border-radius: 6px;
                }
                div {
                  line-height: 32px;
                  // line-height: 48px;
                  // background: #e4760b;
                  background: linear-gradient(150deg, #ff2c41 0%, #ff1232 100%),
                    #ff3333;
                  color: #fff;
                  border-radius: 4px;
                  margin-top: 6px;
                  // font-size: 20px;
                  font-size: 14px;
                }
              }
            }
          }
        }
        .serveBox {
          // background: #fff;
          margin-left: 14px;
          width: 569px;
          height: 68px;
          &-serve {
            color: red;
            border-bottom: 1px solid red;
            cursor: pointer;
          }
          &-item {
            div {
              text-align: left;
              margin: 0;
              line-height: normal;
              // font-size: 16px;
              // color: #2d2d2d;
              font-size: 14px;
              color: #fff;
              text-indent: 1.5em; /* 设置整体缩进 */
              &:first-child {
                text-indent: 0;
              }
            }
          }
          &-title {
            color: #fff;
            margin-top: 16px;
            div {
              text-align: left;
              margin: 0;
              line-height: normal;

              font-size: 16px;
              color: #ff3333;
              // text-indent: 1.5em; /* 设置整体缩进 */
              font-size: 16px;

              font-weight: 700;

              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
/deep/ .videoPlayer-dimensions {
  width: 1363px;
  height: 660px;
}
.header-title {
  color: #fff;
}
/deep/ .el-menu--horizontal {
  // background-color: #070507;
}
/deep/ .el-menu--horizontal > .el-menu-item {
  color: #fff;
}
/deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  // background-color: #070507;
  background: rgba(7, 5, 7, 0);
}
</style>